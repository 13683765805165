import { forwardProps } from 'd2/utils/props'
import {
  forwardRef,
  isValidElement,
  memo,
  useCallback,
  useMemo,
} from 'react'
import { isString } from 'lodash-es'
import { px } from 'd2/utils/style'
import FaIcon from 'd2/components/FaIcon'
import ImageFallback from 'd2/components/ImageFallback'
import UserIcon from '../UserIcon'
import useStyles, { DEFAULT_SIZE } from './styles'
import type { Props } from './types'

const AvatarIcon = memo<Props>(forwardRef(({
  animate,
  appleImage,
  background,
  className,
  disabled,
  fontSize,
  forceBackground,
  forceLightBackground,
  gray,
  hovering,
  icon,
  iconSrc,
  iconWeight,
  marginRight,
  name,
  size,
  spotifyImage,
  square = false,
  testID,
  ...props
}, ref) => {
  const {
    classes,
    cx,
  } = useStyles({
    background,
    iconSrc,
    size,
  })
  const marginStyles = marginRight
    ? {
      marginRight: px(marginRight),
    }
    : null

  const iconColorClassName: string = useMemo(
    () => cx(animate || disabled || gray
      ? {
        [classes.gray]: (disabled && !animate) || (!hovering && animate) || gray,
        [classes.white]: (hovering && animate) || forceLightBackground,
      }
      : classes.white),
    [
      animate,
      classes,
      cx,
      disabled,
      forceLightBackground,
      gray,
      hovering,
    ],
  )

  const hasAppleImage: boolean = useMemo(() => !!appleImage && !!iconSrc, [appleImage, iconSrc])
  const hasSpotifyImage: boolean = useMemo(() => !!spotifyImage && !!iconSrc, [spotifyImage, iconSrc])

  const fallbackImage = useMemo(() => (<UserIcon
    size={size ?? DEFAULT_SIZE}
    testID={testID && `${testID}-ImageFallback-UserIcon`}
    text={name}
  />), [size, name, testID])

  const renderImage = useCallback(
    () =>
      name
        ? <ImageFallback
          alt='icon'
          className={cx(classes.imgSize, { [classes.circleImg]: !square })}
          fallbackImage={fallbackImage}
          src={iconSrc}
          testID={testID && `${testID}-ImageFallback`}
        />
        : <img
          alt='icon'
          className={cx(classes.imgSize, { [classes.circleImg]: !square })}
          data-test-id={testID && `${testID}-Image`}
          src={iconSrc}
        />,
    [
      square,
      classes,
      cx,
      iconSrc,
      name,
      testID,
      fallbackImage,
    ],
  )
  return (
    <div
      className={cx(className, classes.container, classes.size)}
      data-test-id={testID}
      // @ts-expect-error Type 'ForwardedRef<unknown>' is not assignable to type 'LegacyRef<HTMLSpanElement> | undefined'.
      ref={ref}
      style={marginStyles}
      {...forwardProps(props)}
    >
      <div
        className={cx({
          [classes.circle]: !square,
          [classes.grayBackground]: forceBackground,
          [classes.lightBackground]: forceLightBackground,
          [classes.appearFromCenter]: hovering && animate,
          [classes.appleImageBackground]: hasAppleImage,
          [classes.spotifyImageBackground]: hasSpotifyImage,
          [classes.background]:
            !disabled
            && (((!animate || hovering) && !forceLightBackground)
              || (hovering && forceLightBackground)
            )
            && !hasAppleImage
            && !hasSpotifyImage,
        })}
      />
      { (!hasAppleImage && !hasSpotifyImage) && <div
        className={cx(classes.childComponents, iconColorClassName, {
          [classes.appearFromLeft]: hovering && animate,
        })}
      >
        {
          isValidElement(icon)
            ? icon
            : icon && isString(icon)
              ? <FaIcon
                className={cx(
                  iconColorClassName,
                  {
                    [classes.iconLarge]: fontSize === 'large',
                    [classes.iconMedium]: fontSize === 'medium',
                    [classes.iconSmall]: fontSize === 'small',
                    [classes.icon]: !fontSize || fontSize === 'normal',
                  },
                )}
                icon={icon}
                weight={iconWeight ?? 'regular'}
              />
              : renderImage()
        }
      </div> }
    </div>
  )
}))

AvatarIcon.displayName = 'AvatarIcon'

export default AvatarIcon
