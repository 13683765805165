import { Phone } from 'd2/components/Responsive'
import { memo } from 'react'
import SpaceBetween from 'd2/components/Layout/SpaceBetween'

type StackedOnPhoneType = {
  children: React$Node,
  tinyButtons?: boolean
}

const StackedOnPhone = memo<StackedOnPhoneType>(({ children, tinyButtons }) => (
  <Phone>
    { (isPhone) => isPhone && !tinyButtons
      ? (
        <>
          { children }
        </>
      )
      : (
        <SpaceBetween>
          { children }
        </SpaceBetween>
      ) }
  </Phone>))

StackedOnPhone.displayName = 'StackedOnPhone'

export default StackedOnPhone
