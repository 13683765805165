import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  black: {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    color: theme.colors?.black,
  },
  bold: {
    ...theme.fontExtraBoldStyles,
  },
  danger: {
    color: theme.branding?.danger,
  },
  darkGray: {
    // TODO: This is breaking on D1 for colors being missing.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    color: theme.colors?.darkGray,
  },
  gray: {
    color: theme.branding?.grayText,
  },
  italic: {
    fontStyle: 'italic',
  },
  light: {
    ...theme.fontLightStyles,
  },
  lightItalic: {
    ...theme.fontLightStyles,
    fontStyle: 'italic',
  },
  normal: {
    ...theme.fontNormalStyles,
  },
  primary: {
    color: theme.branding?.primaryColor,
  },
  secondary: {
    color: theme.branding?.secondaryColor,
  },
  semibold: {
    ...theme.fontBoldStyles,
  },
  semiboldItalic: {
    ...theme.fontBoldStyles,
    fontStyle: 'italic',
  },
  success: {
    color: theme.branding?.success,
  },
  tertiary: {
    color: theme.branding?.tertiaryColor,
  },
  warning: {
    color: theme.branding?.warning,
  },
  white: {
    color: theme.branding?.white,
  },
  yellow: {
    color: theme.branding?.highlight,
  },
} as const)

export default makeStyles({
  name: 'Base',
})(styles)
