import { SPACING, SPACING_HALF } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  buttonsWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: px(SPACING_HALF),
  },
  complete: {
    color: theme.branding?.success,
  },
  contentWithMargin: {
    margin: `${px(SPACING)} 0 ${px(SPACING)} ${px(SPACING)}`,
  },
  incomplete: {
    color: theme.branding?.danger,
  },
  paddingBottom: {
    paddingBottom: px(SPACING),
  },
  paddingLeft: {
    paddingLeft: px(SPACING),
  },
  subtitle: {
    paddingTop: px(SPACING_HALF),
  },
} as const)

export default makeStyles({
  name: 'CardSubSection',
})(styles)
