import { memo } from 'react'
import Base from '../Base'
import useStyles from './styles'
import type { Props } from '../Base/types'

const Heading5 = memo<Props>(({
  children,
  className,
  color,
  component = 'h5',
  testID,
  variant,
}) => {
  const { classes, cx } = useStyles()

  return (
    <Base
      className={cx(classes.heading5, className)}
      color={color}
      component={component}
      testID={testID}
      variant={variant}
    >
      { children }
    </Base>
  )
})

Heading5.displayName = 'Heading5'

export default Heading5
