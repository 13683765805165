import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
} as const)

export default makeStyles({
  name: 'SpaceBetween',
})(styles)
