import { memo } from 'react'
import Base from '../Base'
import useStyles from './styles'
import type { Props } from '../Base/types'

const BodySmall = memo<Props>(({
  children,
  className,
  color,
  component = 'span',
  testID,
  variant,
  ...props
}) => {
  const { classes, cx } = useStyles()

  return (
    <Base
      className={cx(classes.bodySmall, className)}
      color={color}
      component={component}
      testID={testID}
      variant={variant}
      {...props}
    >
      { children }
    </Base>
  )
})

BodySmall.displayName = 'BodySmall'

export default BodySmall
