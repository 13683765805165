import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

type StylesProps = {
  fontSize?: number,
}

const styles = (theme: MergedThemes, { fontSize }: StylesProps) => ({
  size: {
    fontSize: fontSize ? px(fontSize) : undefined,
  },
  youtube: {
    color: theme.colors.youtubeRed,
  },
} as const)
export default makeStyles<StylesProps>({
  name: 'FontAwesome',
})(styles)
