import { forwardProps } from 'd2/utils/props'
import { forwardRef, memo } from 'react'
import { onlyText } from 'react-children-utilities'
import { stringToColor } from 'd2/utils/StringHelpers'
import AvatarIcon from 'd2/components/AvatarIcon/AvatarIcon'
import Heading5 from 'd2/components/Typography/Heading5'
import useStyles from './styles'
import useTheme from 'd2/hooks/useTheme'
import type { Props } from './types'

const DEFAULT_SIZE = 50

const UserIcon = memo<Props>(forwardRef(({
  size = DEFAULT_SIZE,
  testID,
  text,
  ...props
}, ref) => {
  const theme = useTheme()
  const color = theme.branding ? stringToColor(onlyText(text), theme.branding.userAvatarColors) : ''
  const {
    classes,
  } = useStyles({
    color,
    size,
  })

  return (
    <AvatarIcon
      background={color}
      icon={text
        ? <Heading5
          className={classes.root}
          component='span'
          variant='light'
        >
          { onlyText(text).charAt(0) }
        </Heading5>
        : undefined}
      // @ts-expect-error Type 'ForwardedRef<unknown>' is not assignable to type 'LegacyRef<HTMLSpanElement> | undefined'.
      ref={ref}
      size={size}
      testID={testID}
      {...forwardProps(props)}
    />
  )
}))

UserIcon.displayName = 'UserIcon'

export default UserIcon
