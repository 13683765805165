import { createTheme } from '@mui/material/styles'

const theme = createTheme()

// https://mui.com/material-ui/customization/breakpoints/

// export const screenXsMin = theme.breakpoints.values.xs // Should never need this
export const screenSmMin = theme.breakpoints.values.sm
export const screenMdMin = theme.breakpoints.values.md
export const screenLgMin = theme.breakpoints.values.lg
export const screenXlMin = theme.breakpoints.values.xl

export const screenXsMax = screenSmMin - 1
export const screenSmMax = screenMdMin - 1
export const screenMdMax = screenLgMin - 1
export const screenLgMax = screenXlMin - 1
// export const screenXlMax: number = ??? // Should never need this
