import { px, vh } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    containerPaddingBottom,
    containerPaddingLeft,
    containerPaddingRight,
    containerPaddingTop,
    gridContainerPaddingBottom,
    gridContainerPaddingLeft,
    gridContainerPaddingRight,
    gridContainerPaddingTop,
  }: MergedThemes,
) => ({
  centerColumns: {
    justifyContent: 'center',
  },
  col: {
    float: 'none',
    marginRight: '0',
    width: 'auto',
  },
  container: {
    paddingBottom: px(containerPaddingBottom),
    paddingLeft: px(containerPaddingLeft),
    paddingRight: px(containerPaddingRight),
    paddingTop: px(containerPaddingTop),
  },
  fitViewportHeight: {
    height: vh(100),
  },
  grid: {},
  gridContainer: {
    paddingBottom: px(gridContainerPaddingBottom),
    paddingLeft: px(gridContainerPaddingLeft),
    paddingRight: px(gridContainerPaddingRight),
    paddingTop: px(gridContainerPaddingTop),
  },
  halfPadding: {
    paddingBottom: px(gridContainerPaddingBottom / 2),
    paddingLeft: px(gridContainerPaddingLeft / 2),
    paddingRight: px(gridContainerPaddingRight / 2),
    paddingTop: px(gridContainerPaddingTop / 2),
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddingForAll: {
    padding: 0,
  },
} as const)

export default makeStyles({
  name: 'Grid',
})(styles)
