import { MAX, SPACING_HALF } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'
import type { Props } from './types'

type StylesProps = Pick<Props,
| 'align'
| 'paddingHorizontal'
| 'paddingVertical'>

const styles = (theme: MergedThemes, { align, paddingHorizontal, paddingVertical }: StylesProps) => ({
  container: {
    alignItems: align ?? 'center',
    backgroundColor: theme.palette.common.white,
    border: `${px(1)} solid ${theme.colors.faintGray}`,
    boxShadow: `0 ${px(1)} ${px(3)} 0 rgba(239,239,239,0.5)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${px(paddingVertical ?? 0)} ${px(paddingHorizontal ?? 0)}`,
  },
  fullHeight: {
    boxSizing: 'border-box',
    height: MAX,
  },
  title: {
    padding: `0 0 ${px(SPACING_HALF)} 0`,
    textAlign: 'center',
  },

} as const)

export default makeStyles<StylesProps>({
  name: 'CardTile',
})(styles)
