import { TypographyContext, useTypographyContext } from './context'
import { memo, useMemo } from 'react'
import useStyles from './styles'
import type { Props } from './types'

const Base = memo<Props>(({
  children,
  className,
  color,
  component = 'span',
  testID,
  variant,
  ...props
}) => {
  const { classes, cx } = useStyles()
  const { parent } = useTypographyContext()

  const Component = useMemo(
    // eslint-disable-next-line react-memo/require-memo
    () => parent ? 'span' : component,
    [parent, component],
  )

  const contextValue = useMemo(
    () => ({
      parent: component,
    }),
    [component],
  )

  const variantClassName = useMemo(
    () => ({
      [classes.semibold]: variant === 'semibold',
      [classes.semiboldItalic]: variant === 'semibold-italic',
      [classes.italic]: variant === 'italic',
      [classes.bold]: variant === 'bold',
      [classes.normal]: !variant || variant === 'normal',
      [classes.light]: variant === 'light',
      [classes.lightItalic]: variant === 'light-italic',
    }),
    [classes, variant],
  )

  const colorClassName = useMemo(
    () => ({
      [classes.white]: color === 'white',
      [classes.danger]: color === 'danger',
      [classes.darkGray]: color === 'darkGray',
      [classes.gray]: color === 'gray',
      [classes.primary]: color === 'primary',
      [classes.secondary]: color === 'secondary',
      [classes.success]: color === 'success',
      [classes.tertiary]: color === 'tertiary',
      [classes.warning]: color === 'warning',
      [classes.black]: color === 'black',
      [classes.yellow]: color === 'yellow',
    }),
    [classes, color],
  )

  return (
    <Component
      className={cx(className, variantClassName, colorClassName)}
      data-test-id={testID}
      {...props}
    >
      <TypographyContext.Provider
        value={contextValue}
      >
        { children }
      </TypographyContext.Provider>
    </Component>

  )
})

Base.displayName = 'Base'

export default Base
