import { memo } from 'react'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import MaterialBox from '@mui/material/Box'

export type Props = Partial<ComponentProps<typeof MaterialBox> & {
  children: React$Node
  testID?: string
}>

const Box = memo<Props>(({
  ...props
}) => <MaterialBox {...props} />)

Box.displayName = 'Box'

export default Box
