import {
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  divider: {
    borderColor: theme.palette.grey.A100,
    color: theme.palette.grey.A100,
    height: 0,
  },
  doubleMargin: {
    margin: `${px(SPACING_DOUBLE)} 0`,
  },
  halfMargin: {
    margin: `${px(SPACING_HALF)} 0`,
  },
  halfMarginBottom: {
    marginBottom: px(SPACING_HALF),
  },
  halfMarginTop: {
    marginTop: px(SPACING_HALF),
  },
  halfSideMargin: {
    marginLeft: px(SPACING_HALF),
    marginRight: px(SPACING_HALF),
  },
  margin: {
    margin: `${px(SPACING)} 0`,
  },
  noMarginBottom: {
    marginBottom: px(0),
  },
  noMarginTop: {
    marginTop: px(0),
  },
  sideMargin: {
    marginLeft: px(SPACING_HALF),
    marginRight: px(SPACING_HALF),
  },
} as const)

export default makeStyles({
  name: 'Divider',
})(styles)
