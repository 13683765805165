import { createContext, useContext } from 'react'
import type { TypographyContextType } from './types'

export const TypographyContext = createContext<TypographyContextType>({
  parent: undefined,
})

export function useTypographyContext (): TypographyContextType {
  return useContext<TypographyContextType>(TypographyContext)
}
