import { AVATAR_EXTRA_SMALL_ICON_FONT_SIZE, MAX } from 'd2/constants'
import {
  FONT_SIZE_EXTRA_LARGE_ICON,
  FONT_SIZE_HUGE,
  FONT_SIZE_MEDIUM_ICON,
} from 'd2/constants/fonts'
import { appearFromCenter, appearFromSide } from 'd2/animations'
import { lighten } from '@mui/material/styles'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'
import type { Props } from './types'

export const DEFAULT_SIZE = 50
const {
  itemAnimationStyles,
} = appearFromSide({
  delayBase: 100,
  direction: 'left',
})

const {
  itemAnimationStyles: itemAnimationStylesAppearCenter,
} = appearFromCenter()

type StylesProps = Pick<Props, 'background' | 'iconSrc' | 'size'>

const styles = (
  theme: MergedThemes,
  { background, iconSrc, size }: StylesProps,
) => ({
  appearFromCenter: {
    ...itemAnimationStylesAppearCenter,
  },
  appearFromLeft: {
    ...itemAnimationStyles,
    '& *': {
      transition: 'color 0.5s ease',
    },
  },
  appleImageBackground: {
    backgroundImage: iconSrc ? `url(${iconSrc})` : undefined,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    transform: 'scale(1.4)',
  },
  background: {
    background: background ?? theme.colors.faintGray,
    height: MAX,
    position: 'absolute',
    width: MAX,
  },
  childComponents: {
    zIndex: 1,
  },
  circle: {
    alignItems: 'center',
    borderRadius: '50%',
    height: MAX,
    overflow: 'hidden',
    position: 'absolute',
    width: MAX,
    zIndex: 0,
  },
  circleImg: {
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    borderColor: theme.colors.faintGray,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: px(1),
    display: 'block',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    lineHeight: 0,
    overflow: 'hidden',
    position: 'relative',
  },
  gray: {
    color: theme.colors.gray,
  },
  grayBackground: {
    background: theme.colors.faintGray,
  },
  icon: {
    fontSize: px(FONT_SIZE_HUGE),
  },
  iconLarge: {
    fontSize: px(FONT_SIZE_EXTRA_LARGE_ICON),
  },
  iconMedium: {
    fontSize: px(FONT_SIZE_MEDIUM_ICON),
  },
  iconSmall: {
    fontSize: px(AVATAR_EXTRA_SMALL_ICON_FONT_SIZE),
  },
  imgSize: {
    height: 'auto',
    width: px(size ?? DEFAULT_SIZE),
  },
  lightBackground: {
    background: theme.branding?.primaryColor ? lighten(theme.branding.primaryColor, 0.5) : theme.colors.faintGray,
    color: theme.palette.common.white,
  },
  size: {
    height: px(size ?? DEFAULT_SIZE),
    width: px(size ?? DEFAULT_SIZE),
  },
  spotifyImageBackground: {
    backgroundImage: iconSrc ? `url(${iconSrc})` : undefined,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
  },
  white: {
    color: theme.palette.common.white,
  },
} as const)
export default makeStyles<StylesProps>({
  name: 'AvatarIcon',
})(styles)
