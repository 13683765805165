import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  WIDESCREEN_HEIGHT,
  WIDESCREEN_PERCENT,
  WIDESCREEN_WIDTH,
} from './constants'
import { MAX } from 'd2/constants'
import { percent, px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const background = {
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
}

const styles = () => ({
  contain: {
    backgroundSize: 'contain',
  },
  cover: {
    backgroundSize: 'cover',
  },
  fullWidthImage: {
    ...background,
    paddingTop: percent(WIDESCREEN_PERCENT),
    position: 'relative',
    width: MAX,
  },
  fullWidthWrapper: {
    width: MAX,
  },
  image: {
    ...background,
  },
  imageNormalDimensions: {
    height: px(IMAGE_HEIGHT),
    width: px(IMAGE_WIDTH),
  },
  imageWidescreen: {
    ...background,
    height: px(WIDESCREEN_HEIGHT),
    position: 'relative',
    width: px(WIDESCREEN_WIDTH),
  },
  imageWrapper: {
    minWidth: px(WIDESCREEN_WIDTH),
  },
  square: {
    ...background,
    height: px(WIDESCREEN_HEIGHT),
    width: px(WIDESCREEN_HEIGHT),
  },
  squareWrapper: {
    minWidth: px(WIDESCREEN_HEIGHT),
  },
} as const)
export default makeStyles({
  name: 'MetadataImage',
})(styles)
