import { MergedThemes } from 'd2/types'
import { getColorObject } from 'd2/themes/light'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

type StylesProps = { size: number, color: string }

const styles = (_theme: MergedThemes, { color, size }: StylesProps) => ({
  root: {
    color: getColorObject(color).contrastText,
    fontSize: px(size / 2),
    textTransform: 'uppercase',
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'UserIcon',
})(styles)
