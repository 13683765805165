import type { FontAwesomeWeight } from './types'

/*
Gets the first css class for a Font Awesome icon.
*/
export const classForFill: (a: FontAwesomeWeight) => string = (weight) => {
  switch (weight) {
  case 'brand': {
    return 'fab'
  }
  case 'solid': {
    return 'fas'
  }
  case 'regular': {
    return 'far'
  }
  default: {
    return 'fal'
  }
  }
}

// I'm pretty sure this is no longer used. Commented out for now.
// export const getIcon: (a: AllowedIcons) => FontAwesomeIcon | null | undefined = (icon) => {
//   if (includes(FONT_AWESOME_ICONS, icon)) return icon
//   throw new Error(`invalid tag ${icon || 'undefined'} passed to tagsSwitch, expected one of FontAwesomeIcon`)
// }
