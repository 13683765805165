import { memo } from 'react'
import Box from 'd2/components/Box'
import type {
  Align,
  Props,
  SecondaryAlign,
} from './types'

export type ResponsiveStyleValue<T> = T | T[]

type JustifyContent = 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between' | 'space-evenly'

const alignSwitch: (a?: Align | null) => ResponsiveStyleValue<JustifyContent | JustifyContent[] | undefined> = (align) => {
  switch (align) {
  case 'start': { return 'flex-start'
  }
  case 'end': { return 'flex-end'
  }
  case 'center': { return 'center'
  }
  case 'spaceBetween': { return 'space-between'
  }
  case 'spaceAround': { return 'space-around'
  }
  case 'spaceEvenly': { return 'space-evenly'
  }
  default: {
    return undefined
  }
  }
}

type AlignItems = 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch'

const secondaryAlignSwitch: (a?: SecondaryAlign | null) => ResponsiveStyleValue<AlignItems | AlignItems[] | undefined> = (secondaryAlign) => {
  switch (secondaryAlign) {
  case 'start': { return 'flex-start'
  }
  case 'end': { return 'flex-end'
  }
  case 'center': { return 'center'
  }
  case 'stretch': { return 'stretch'
  }
  case 'baseline': { return 'baseline'
  }
  default: {
    return undefined
  }
  }
}

const Flexbox = memo<Props>(({
  align,
  children,
  className,
  reverse,
  secondaryAlign,
  vertical,
  ...props
}) => (
  <Box
    className={className}
    sx={{
      ...props,
      alignItems: secondaryAlignSwitch(secondaryAlign),
      display: 'flex',
      flexDirection: vertical ? `column${reverse ? '-reverse' : ''}` : `row${reverse ? '-reverse' : ''}`,
      justifyContent: alignSwitch(align),
    }}
  >
    { children }
  </Box>
))

Flexbox.displayName = 'Flexbox'

export default Flexbox
