import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

// TODO: Move sizes to theme
const styles = (theme: MergedThemes) => ({
  bodySmall: {
    fontFamily: theme.branding?.primaryFontFamily,
    fontSize: px(14),
    lineHeight: px(18.5),
  },
} as const)

export default makeStyles({
  name: 'BodySmall',
})(styles)
