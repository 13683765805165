import { svgDataImg } from 'd2/svg'
import useTheme from 'd2/hooks/useTheme'

const AUDIO_MEDIA_TYPE = 'audio' as const

export default function useMediaThumbnail ({
  imageUrl,
  mediaType,
}: {
  imageUrl: string | null | undefined,
  mediaType: string | null | undefined
}) {
  const theme = useTheme()

  if (imageUrl) return imageUrl
  if (!mediaType) return ''

  const audioThumbnail = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 400 225" style="enable-background:new 0 0 400 225;" xml:space="preserve">
    <style type="text/css">
      .gradientPrimary{fill:url(#SVGID_1_);}
      .st0{fill:url(#SVGID_2_);}
      .st1{fill:#FFFFFF;}
    </style>
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="1080" x2="0.8723" y2="1080.489">
      <stop offset="0" stop-color="${theme.branding?.primaryGradientStart ?? 'transparent'}"/>
      <stop offset="1" stop-color="${theme.branding?.primaryGradientEnd ?? 'transparent'}"/>
    </linearGradient>
    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1.7125" y1="1.3263" x2="402.0248" y2="225.7692">
      <stop offset="0" stop-color="${theme.branding?.primaryGradientStart ?? 'transparent'}"/>
      <stop offset="1" stop-color="${theme.branding?.primaryGradientEnd ?? 'transparent'}"/>
    </linearGradient>
    <rect class="st0" width="400" height="225"/>
    <g>
      <path class="st1" d="M245.9,72.3v60.3c0,7.9-7.7,14.4-17.2,14.4s-17.2-6.4-17.2-14.4s7.7-14.4,17.2-14.4c4.4,0,8.4,1.4,11.5,3.7
        V95.3l-51.7,17.2v31.6c0,7.9-7.7,14.4-17.2,14.4s-17.2-6.4-17.2-14.4s7.7-14.4,17.2-14.4c4.4,0,8.4,1.4,11.5,3.7V89.5
        c0-2.5,1.6-4.7,3.8-5.5l52.2-17.2c0.4-0.1,1.2-0.3,1.7-0.3C243.4,66.6,245.9,69,245.9,72.3z M182.8,144.1c0-4.7-5.3-8.6-11.5-8.6
        s-11.5,3.9-11.5,8.6s5.3,8.6,11.5,8.6S182.8,148.7,182.8,144.1z M240.2,72.4l-51.8,17.1v0h0.1v16.9l51.7-17.2V72.4z M240.2,132.6
        c0-4.7-5.3-8.6-11.5-8.6s-11.5,3.9-11.5,8.6s5.3,8.6,11.5,8.6S240.2,137.3,240.2,132.6z"/>
    </g>
  </svg>`

  const videoThumbnail = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 400 225" style="enable-background:new 0 0 400 225;" xml:space="preserve">
    <style type="text/css">
      .gradientPrimary{fill:url(#SVGID_1_);}
      .st0{fill:url(#SVGID_2_);}
      .st1{fill:#FFFFFF;}
    </style>
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="1080" x2="0.8723" y2="1080.489">
      <stop offset="0" stop-color="${theme.branding?.primaryGradientStart ?? 'transparent'}"/>
      <stop offset="1" stop-color="${theme.branding?.primaryGradientEnd ?? 'transparent'}"/>
    </linearGradient>
    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1.7125" y1="1.3263" x2="402.0248" y2="225.7692">
      <stop offset="0" stop-color="${theme.branding?.primaryGradientStart ?? 'transparent'}"/>
      <stop offset="1" stop-color="${theme.branding?.primaryGradientEnd ?? 'transparent'}"/>
    </linearGradient>
    <rect class="st0" width="400" height="225"/>
    <g>
      <path class="st1" d="M245.9,82.4v60.3c0,2.4-1.9,4.3-4.3,4.3h-1.4v-3.6c0-1.2-1-2.2-2.2-2.2h-7.2c-1.2,0-2.2,1-2.2,2.2v3.6h-57.4
        v-3.6c0-1.2-1-2.2-2.2-2.2H162c-1.2,0-2.2,1-2.2,2.2v3.6h-1.4c-2.4,0-4.3-1.9-4.3-4.3V82.4c0-2.4,1.9-4.3,4.3-4.3h1.4v3.6
        c0,1.2,1,2.2,2.2,2.2h7.2c1.2,0,2.2-1,2.2-2.2v-3.6h57.4v3.6c0,1.2,1,2.2,2.2,2.2h7.2c1.2,0,2.2-1,2.2-2.2v-3.6h1.4
        C244,78.1,245.9,80,245.9,82.4z M171.3,91.7c0-1.2-1-2.2-2.2-2.2H162c-1.2,0-2.2,1-2.2,2.2v7.2c0,1.2,1,2.2,2.2,2.2h7.2
        c1.2,0,2.2-1,2.2-2.2V91.7z M171.3,108.9c0-1.2-1-2.2-2.2-2.2H162c-1.2,0-2.2,1-2.2,2.2v7.2c0,1.2,1,2.2,2.2,2.2h7.2
        c1.2,0,2.2-1,2.2-2.2V108.9z M171.3,126.1c0-1.2-1-2.2-2.2-2.2H162c-1.2,0-2.2,1-2.2,2.2v7.2c0,1.2,1,2.2,2.2,2.2h7.2
        c1.2,0,2.2-1,2.2-2.2V126.1z M223,85.9c0-1.2-1-2.2-2.2-2.2h-41.6c-1.2,0-2.2,1-2.2,2.2v21.5c0,1.2,1,2.2,2.2,2.2h41.6
        c1.2,0,2.2-1,2.2-2.2V85.9z M223,117.5c0-1.2-1-2.2-2.2-2.2h-41.6c-1.2,0-2.2,1-2.2,2.2v21.5c0,1.2,1,2.2,2.2,2.2h41.6
        c1.2,0,2.2-1,2.2-2.2V117.5z M240.2,91.7c0-1.2-1-2.2-2.2-2.2h-7.2c-1.2,0-2.2,1-2.2,2.2v7.2c0,1.2,1,2.2,2.2,2.2h7.2
        c1.2,0,2.2-1,2.2-2.2V91.7z M240.2,108.9c0-1.2-1-2.2-2.2-2.2h-7.2c-1.2,0-2.2,1-2.2,2.2v7.2c0,1.2,1,2.2,2.2,2.2h7.2
        c1.2,0,2.2-1,2.2-2.2V108.9z M240.2,126.1c0-1.2-1-2.2-2.2-2.2h-7.2c-1.2,0-2.2,1-2.2,2.2v7.2c0,1.2,1,2.2,2.2,2.2h7.2
        c1.2,0,2.2-1,2.2-2.2V126.1z"/>
    </g>
  </svg>`

  const thumbnail = mediaType === AUDIO_MEDIA_TYPE ? audioThumbnail : videoThumbnail
  return svgDataImg(thumbnail)
}
