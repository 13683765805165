import { memo } from 'react'
import useMediaThumbnail from 'd2/svg/useMediaThumbnail'
import useStyles from './styles'
import type { ImageProps, Props } from './types'

const Image = memo<ImageProps>(({
  className,
  contain = false,
  fullWidth,
  imageUrl,
  isPickerImage,
  square,
}) => {
  const { classes, cx } = useStyles()

  const bgSizeClass = contain ? classes.contain : classes.cover

  return (<div
    className={
      cx(
        className
          ? cx(className, classes.image)
          : isPickerImage
            ? classes.imageNormalDimensions
            : fullWidth
              ? classes.fullWidthImage
              : square
                ? classes.square
                : classes.imageWidescreen,
        bgSizeClass)
    }
    // check if we can use a thumbnail
    style={{ backgroundImage: imageUrl && `url('${imageUrl}')` }}
  />)
})

Image.displayName = 'Image'

const MetadataImage = memo<Props>(({
  contain = false,
  fullWidth,
  image,
  imageClassName,
  imageUrl,
  isPickerImage,
  mediaType,
  square,
}) => {
  const { classes, cx } = useStyles()
  const thumbnail: string = useMediaThumbnail({
    imageUrl,
    mediaType,
  })
  if (image) {
    // TODO: Remove 'as' type assertions because they are unsafe.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return image as React$Element
  }
  if (!thumbnail) return null

  const imageElement = (<Image
    className={imageClassName}
    contain={contain}
    fullWidth={fullWidth}
    imageUrl={thumbnail}
    isPickerImage={isPickerImage}
    square={square}
  />)
  if (imageClassName) {
    return imageElement
  }
  return (
    <div
      className={cx({
        [classes.imageWrapper]: !isPickerImage,
        [classes.fullWidthWrapper]: fullWidth,
        [classes.squareWrapper]: square,
      })}
    >
      { imageElement }
    </div>
  )
})

MetadataImage.displayName = 'MetadataImage'

export default MetadataImage
