import { MAX } from 'd2/constants'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  containerHorizontal: {
    display: 'flex',
  },
  containerVertical: {
    display: 'flex',
    flexDirection: 'column',
    height: MAX,
  },
  stretchChild: {
    flexGrow: 1,
  },
} as const)

export default makeStyles({
  name: 'StretchOne',
})(styles)
