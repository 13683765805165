import { cloneDeep } from 'lodash-es'
import type { Styles } from 'd2/types'

/*
@example
  px(80) => 80px
*/
export const px: (a: number) => string = (number) => `${number}px`

/*
@example
  vh(80) => 80vh
*/
export const vh: (a: number) => string = (number) => `${number}vh`

/*
@example
  ms(400) => 400ms
*/
export const ms: (a: number) => string = (number) => `${number}ms`

/*
@example
  em(0.9) => 0.9em
*/
export const em: (a: number) => string = (number) => `${number}em`

/*
@example
  rem(1.25) => 1.25rem
*/
export const rem: (a: number) => string = (number) => `${number}rem`

/*
@example
  percent(1.25) => 1.25%
*/
export const percent: (a: number) => string = (number) => `${number}%`

/*
@example
  negative(1) => -1
*/
export const negative: (a: number) => number = (number) => number * -1

// TODO: fix all link states to use palette
// TODO: Why do we actually have to cloneDeep? Isn't that wasteful? Doesn't that only matter if we were mutating the styles object and didn't want to mutate all references of the object? I don't think that's happening anywhere...
// The order is important. Right takes precedence over left.
export function allLinkStates<TInput extends Styles> (styles: TInput): {
  '&': TInput,
  '&:active': TInput,
  '&:hover': TInput,
  '&:link': TInput,
  '&:visited': TInput
} {
  return {
    '&': cloneDeep(styles),
    '&:active': cloneDeep(styles),
    '&:hover': cloneDeep(styles),
    '&:link': cloneDeep(styles),
    '&:visited': cloneDeep(styles),
  }
}

export function beforeAfterStyles<TInput extends Styles> (styles: TInput): {
  '&:after': TInput,
  '&:before': TInput
} {
  return {
    '&:after': cloneDeep(styles),
    '&:before': cloneDeep(styles),
  }
}
