import { Flexbox } from 'd2/components/Layout'
import { isValidElement, memo } from 'react'
import { useIsMobile } from 'd2/components/Responsive'
import AvatarIcon from 'd2/components/AvatarIcon/AvatarIcon'
import BodySmall from 'd2/components/Typography/BodySmall'
import Heading5 from 'd2/components/Typography/Heading5'
import MetadataImage from 'd2/components/MetadataImage'
import Truncate from 'd2/components/Typography/Truncate'
import useStyles from './styles'
import type { Props } from './types'

const MetadataSummary = memo<Props>(({
  center,
  children,
  circleImage,
  color,
  contain = false,
  containerClassName,
  containerComponent: ContainerComponent = 'div',
  fullWidth = false,
  image,
  imageClassName,
  imageOnly,
  imageSize,
  imageUrl,
  inPicker,
  insidePickerButton,
  itemAlign, // TODO: refactor component and remove the need for custom padding
  labelMaxWidth,
  mediaType,
  noImageMarginRight,
  noPadding,
  noPaddingLeft,
  noTextMargin,
  onClick,
  onMouseEnter,
  onMouseLeave,
  smallPadding,
  smallPaddingTop,
  squareImage,
  subText,
  textClassName,
  title,
  truncateSubtitle = true,
  truncateTitle = true,
  variant,
  verticalStretch,
  withWidescreen,
}) => {
  const isMobile = useIsMobile()
  const { classes, cx } = useStyles({
    labelMaxWidth,
  })

  return (
    <ContainerComponent
      className={cx(
        classes.container,
        {
          [classes.containerWithSmallPadding]: smallPadding,
          [classes.containerWithNoPadding]: noPadding,
          [classes.containerWithNoPaddingLeft]: noPaddingLeft,
          [classes.fullWidth]: fullWidth,
          [classes.itemAlignStart]: itemAlign === 'start',
          [classes.vertical]: variant === 'vertical',
          [classes.verticalStretch]: verticalStretch,
        },
        containerClassName,
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        color
          ? <div
            className={classes.color}
            style={{ backgroundColor: color }}
          />
          : circleImage && imageUrl
            ? (
              <AvatarIcon
                className={cx(
                  classes.avatarIcon,
                  classes.circleAvatarIcon,
                  {
                    [classes.noImageMarginRight]: noImageMarginRight,
                  })}
                iconSrc={imageUrl}
                size={imageSize}
                square={false}
              />
            )
            : (
              <MetadataImage
                contain={contain}
                fullWidth={fullWidth && isMobile}
                image={image}
                imageClassName={imageClassName}
                imageUrl={imageUrl}
                isPickerImage={inPicker}
                mediaType={mediaType}
                square={squareImage}
              />
            )
      }
      {
        !imageOnly && <Flexbox
          align='center'
          className={cx(
            classes.text,
            textClassName,
            imageClassName || withWidescreen ? classes.textWidthWidescreen : variant === 'vertical' ? null : classes.textWidth,
            {
              [classes.textCenter]: center,
              [classes.noTextMargin]: noTextMargin,
              [classes.textInsidePickerButton]: insidePickerButton,
              [classes.textMargin]: !noTextMargin && !insidePickerButton,
            },

          )}
          secondaryAlign='start'
          vertical
        >
          {
            title && <div className={classes.labelMaxWidth}>
              {
                (truncateTitle
                  ? (<Truncate
                    title={title}
                  >
                    {
                      isValidElement(title)
                        ? title
                        : <Heading5>
                          { title }
                        </Heading5>
                    }
                  </Truncate>)
                  : <>
                    {
                      isValidElement(title)
                        ? title
                        : <Heading5>
                          { title }
                        </Heading5>
                    }
                  </>)
              }
            </div>
          }
          {
            subText && <div className={classes.labelMaxWidth}>
              {
                (truncateSubtitle
                  ? (<Truncate
                    smallPaddingTop={smallPaddingTop}
                    title={subText}
                  >
                    {
                      isValidElement(subText)
                        ? subText
                        : <BodySmall color='gray'>
                          { subText }
                        </BodySmall>
                    }
                  </Truncate>)
                  : <>
                    {
                      isValidElement(subText)
                        ? subText
                        : <BodySmall color='gray'>
                          { subText }
                        </BodySmall>
                    }
                  </>)
              }
            </div>
          }
        </Flexbox>
      }

      { children }
    </ContainerComponent>
  )
})

MetadataSummary.displayName = 'MetadataSummary'

export default MetadataSummary
