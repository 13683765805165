import { toLower } from 'lodash-es'
import colors from 'd2/constants/colors'
import tinycolor from 'tinycolor2'
import type { ColorObject } from 'd2/types'

// Utility function to combine two rgb objects via Multiply
function multiplyRgb (rgb1: tinycolor.ColorFormats.RGBA, rgb2: tinycolor.ColorFormats.RGBA) {
  const blue: number = Math.floor(rgb1.b * rgb2.b / 255)
  const green: number = Math.floor(rgb1.g * rgb2.g / 255)
  const red: number = Math.floor(rgb1.r * rgb2.r / 255)

  return tinycolor(`rgb ${red} ${green} ${blue}`)
}

/**
 * Pulled code from open source site that design uses to generate material ui themes
 * @see https://github.com/mbitson/mcg
 */
export function getColorObject (hex: string): ColorObject {
  const baseLight = tinycolor(colors.white)
  const baseDark = multiplyRgb(tinycolor(hex).toRgb(), tinycolor(hex).toRgb())
  const baseTriad = tinycolor(hex).tetrad()
  const primary = tinycolor.mix(baseLight, hex, 100)
  const isDark: boolean = primary.isDark()

  const main: string = primary.toHexString()
  const light: string = tinycolor.mix(baseLight, hex, 70).toHexString()
  const dark: string = tinycolor.mix(baseDark, hex, 70).toHexString()

  return {
    '50': tinycolor.mix(baseLight, hex, 12).toHexString(),
    '100': tinycolor.mix(baseLight, hex, 30).toHexString(),
    '200': tinycolor.mix(baseLight, hex, 50).toHexString(),
    '300': light,
    '400': tinycolor.mix(baseLight, hex, 85).toHexString(),
    '500': main,
    '600': tinycolor.mix(baseDark, hex, 87).toHexString(),
    '700': dark,
    '800': tinycolor.mix(baseDark, hex, 54).toHexString(),
    '900': tinycolor.mix(baseDark, hex, 25).toHexString(),
    A100: tinycolor
      // @ts-expect-error TODO: Shouldn't these be baseTriad[3] since there are only 4 elements in the array?
      .mix(baseDark, baseTriad[4], 15)
      .saturate(80)
      .lighten(65)
      .toHexString(),
    A200: tinycolor
      // @ts-expect-error TODO: Shouldn't these be baseTriad[3] since there are only 4 elements in the array?
      .mix(baseDark, baseTriad[4], 15)
      .saturate(80)
      .lighten(55)
      .toHexString(),
    A400: tinycolor
      // @ts-expect-error TODO: Shouldn't these be baseTriad[3] since there are only 4 elements in the array?
      .mix(baseDark, baseTriad[4], 15)
      .saturate(100)
      .lighten(45)
      .toHexString(),
    A700: tinycolor
      // @ts-expect-error TODO: Shouldn't these be baseTriad[3] since there are only 4 elements in the array?
      .mix(baseDark, baseTriad[4], 15)
      .saturate(100)
      .lighten(40)
      .toHexString(),
    contrastDefaultColor: isDark || toLower(hex) === '#41beed' || toLower(hex) === '#ef8606' ? 'light' : 'dark',
    contrastText: isDark || toLower(hex) === '#41beed' || toLower(hex) === '#ef8606' ? 'rgba(255, 255, 255)' : 'rgba(0, 0, 0)',
    dark,
    isDark,
    light,
    main,
  }
}
