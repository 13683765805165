import { classForFill } from './helpers'
import { memo } from 'react'
import { svgDataImg } from 'd2/svg'
import useAudiomackLogo from 'd2/svg/useAudiomackLogo'
import useStyles from './styles'
import type { Props } from './types'

const FaIcon = memo<Props>(({
  className,
  fontSize,
  icon,
  spin,
  weight,
}) => {
  const svg = useAudiomackLogo()
  const { classes, cx } = useStyles({ fontSize })

  return icon === 'audiomack'
    ? <img
      alt={icon}
      height={15}
      src={svgDataImg(svg)}
      width={20}
    />
    : <span
      className={cx(classes.size, {
        [classes.youtube]: icon === 'youtube',
      }, classForFill(weight), `fa-${icon}`, className, spin && 'fa-spin',
      )}
    />
})

FaIcon.displayName = 'FaIcon'

export default FaIcon
