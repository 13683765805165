import { CONTENT_FONT_SIZE, DEFAULT_LINE_HEIGHT } from './constants'
import {
  MAX,
  SPACING,
  SPACING_DOUBLE,
  SPACING_EIGHTH,
  SPACING_HALF,
  SPACING_ZERO,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import { screenXsMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { AlignItems } from './types'
import type { MergedThemes } from 'd2/types'

type StylesProps = { alignButtons?: AlignItems | undefined }

const LARGE_CARD_PADDING_TOP: number = SPACING + SPACING_HALF // 16 + 8 = 24
const SMALL_CARD_PADDING_TOP: number = SPACING + SPACING_EIGHTH // 16 + 4 = 20
const SMALL_CARD_PADDING_TOP_MOBILE: number = SPACING_HALF + SPACING_EIGHTH // 8 + 4 = 12
const styles = ({ fontNormalStyles, ...theme }: MergedThemes, { alignButtons }: StylesProps) => ({
  body: {
    padding: `${px(LARGE_CARD_PADDING_TOP)} ${px(SPACING)} ${px(SPACING)} ${px(SPACING)}`,
  },
  bodyMobile: {
    padding: `${px(SPACING_HALF)} ${px(SPACING_HALF)} ${px(SPACING_HALF)} ${px(SPACING_HALF)}`,
  },
  bodyWithExtraSmallPadding: {
    padding: px(SPACING_HALF),
  },
  bodyWithSmallPadding: {
    padding: `${px(SMALL_CARD_PADDING_TOP)} ${px(SPACING)} ${px(SPACING)} ${px(SPACING)}`,
  },
  bodyWithSmallPaddingMobile: {
    padding: `${px(SMALL_CARD_PADDING_TOP_MOBILE)} ${px(SPACING_HALF)} ${px(SPACING_HALF)} ${px(SPACING_HALF)}`,
  },
  bottomButtonsWrapper: {
    marginTop: px(SPACING_DOUBLE),
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignButtons ?? 'flex-end',
    [`@media (max-width: ${screenXsMax}px)`]: {
      justifyContent: alignButtons ?? 'center',
    },
  },
  container: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 3px 3px 0 rgba(0,0,0,0.14), 0 3px 4px 0 rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)',
    height: 'auto',
  },
  containerNoBorder: {
    border: 'none',
    boxShadow: `0 ${px(1)} ${px(3)} 0 rgba(239, 239, 239, 0.5)`,
  },
  containerNoVerticalBorders: {
    borderColor: `${theme.colors.faintGray}`,
    borderStyle: 'solid',
    borderWidth: `${px(SPACING_ZERO)} ${px(1)}`,
    boxShadow: `0 ${px(1)} ${px(3)} 0 rgba(239, 239, 239, 0.5)`,
  },
  containerWithoutPaper: {
    border: `${px(1)} solid ${theme.colors.faintGray}`,
    boxShadow: `0 ${px(1)} ${px(3)} 0 rgba(239, 239, 239, 0.5)`,
  },
  content: {
    ...fontNormalStyles,
    color: theme.colors.darkGray,
    fontSize: px(CONTENT_FONT_SIZE),
    lineHeight: px(DEFAULT_LINE_HEIGHT),
  },
  hideHorizontalLine: {
    borderColor: 'transparent',
  },
  horizontalLine: {
    color: theme.palette.grey.A100,
    marginBottom: px(SPACING),
    marginTop: px(SPACING_HALF),
  },
  maxHeight: {
    height: MAX,
  },
  noMarginBottom: {
    marginBottom: '0 !important',
  },
  noMarginTop: {
    marginTop: '0 !important',
  },
  noPadding: {
    padding: '0',
  },
  noPaddingTopBottom: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  showHorizontalLine: {
    borderColor: theme.palette.grey.A100,
  },
  stretchBody: {
    height: MAX,
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'Card',
})(styles)
