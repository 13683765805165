import { useMemo } from 'react'
import I18n from 'd2/utils/I18n'
import useTheme from 'd2/hooks/useTheme'
import type { BrandingTheme } from 'd2/types'

export enum Locale {
  en = 'en',
  es = 'es',
}

// Either an object T or a function that receives branding and returns object T.
type TranslationEntries<T extends AO> = T | ((branding?: BrandingTheme | null) => T)

type LoadedCacheEntry<T extends AO> = {
  // Contains the entries once resolved.
  entries?: TranslationEntries<T> | undefined,
  // Contains the promise responsible for dynamically importing the entries.
  promise?: Promise<{ default: TranslationEntries<T> }>,
}

type TMap<T extends AO> = ({
  [key in Locale]?: () => Promise<{ default: TranslationEntries<T> }>
} & {
  en: () => Promise<{ default: TranslationEntries<T> }>, // en is required, not optional.
})

type LoadedForId<T extends AO> = ({
  [key in Locale]?: LoadedCacheEntry<T>
})

type UseLazyTranslations<T extends AO> = () => T

// Inspiration https://gist.github.com/mfrachet/a04cc57a500de85170e2ade4b9406305
let IDS = 0
const loaded: { [id: number]: LoadedForId<AO> | undefined } = {}

export function makeLazyTranslations<T extends AO> (tmap: TMap<T>): UseLazyTranslations<T> {
  const id = IDS++

  // This is the hook that will be returned. E.g. `const t = useTranslations()`
  return () => {
    const loadedForId = loaded[id] ||= {}
    const { branding } = useTheme()

    // TODO: Use `oneOf` util here
    const locale = I18n.locale === Locale.es ? Locale.es : Locale.en

    const loadedForIdForLocale = loadedForId[locale] ||= {}
    const entries = loadedForIdForLocale.entries

    const t = useMemo<T | undefined>(
      () =>
        typeof entries === 'function'
          ? entries(branding)
          : entries,
      [branding, entries],
    )

    if (t) {
      return t
    }

    if (!loadedForIdForLocale.promise) {
      // Cache the promise (TODO: given inputs?) as shown here: https://github.com/vigzmv/react-promise-suspense/blob/master/lib/index.ts#L29
      // TODO: consider using https://github.com/vigzmv/react-promise-suspense directly if maintaining this module becomes a problem.
      loadedForIdForLocale.promise = (tmap[locale] ?? tmap[Locale.en])()
        .then((lazyModule) => {
          const translations = lazyModule.default
          loadedForIdForLocale.entries = translations

          return lazyModule
        })
        // TODO: Consider .catch here and handling errors, like https://github.com/vigzmv/react-promise-suspense/blob/4c86b725317e2f9217e11dee9b1087c0334bc843/lib/index.ts#L36
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw loadedForIdForLocale.promise
  }
}
