/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Fragment, isValidElement } from 'react'
import {
  forEach,
  get,
  includes,
  isString,
  keys,
  last,
  replace,
  some,
  split,
  toString,
  values,
} from 'lodash-es'
import invariant from 'invariant'
import raygun from 'd2/utils/raygun'
import type { Exact } from 'd2/types'

export type LocaleTranslationMap = Exact<{
  [x: string]: LocaleTranslationMap | string
}>

export type Translations = {
  en: LocaleTranslationMap,
  es: LocaleTranslationMap
}

export type Options<T> = Exact<{
  [x: string]: T
}>

export type TranslationResult<T> = $Call1<((a: React$Element | string) => React$Element | string) & ((a?: any[] | boolean | number | string | null) => string), T>

const defaultLocale = 'en'

const I18n = {

  availableLocales: ['en', 'es'] as const,

  interpolate<T extends React$Element | string> (
    translation: string,
    options?: Options<T> | null,
    keyString?: string | null,
  ): TranslationResult<T> {
    if (!options || !includes(translation, '{{')) {
      // @ts-expect-error Type 'string' is not assignable to type '$Call1<((a: string | React$Element<EO>) => string | React$Element<EO>) & ((a?: string | number | boolean | any[] | null | undefined) => string), T>'.ts(2322)
      return translation
    }

    const optionKeys: string[] = keys(options)

    if (some(values(options), (val) => isValidElement(val))) {
      const regex = new RegExp(optionKeys.map((key) => `{{${key}}}`).join('|'), 'g')
      const splits: string[] = split(translation, regex)
      // @ts-expect-error Type 'Element' is not assignable to type '$Call1<((a: string | React$Element<EO>) => string | React$Element<EO>) & ((a?: string | number | boolean | any[] | null | undefined) => string), T>'.ts(2322)
      return (<>
        { translation.match(regex)?.map((match, index: number) => {
          const key: string = replace(match, /{|}/g, '')
          const value: React$Element | string | null | undefined = options[key]

          if (!value) {
            trackInterpolationError({
              keyString,
              options,
              translation,
            })
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              { splits[index] }
              { value || match }
            </Fragment>
          )
        }) }
        { last(splits) }
      </>)
    }

    forEach(optionKeys, (key: string) => {
      const value = options?.[key]
      if (value && includes(translation, `{{${key}}}`)) {
        translation = replace(translation, `{{${key}}}`, toString(value))
      } else {
        trackInterpolationError({
          keyString,
          options,
          translation,
        })
      }
    })

    // @ts-expect-error Type 'string' is not assignable to type '$Call1<((a: string | React$Element<EO>) => string | React$Element<EO>) & ((a?: string | number | boolean | any[] | null | undefined) => string), T>'.ts(2322)
    return translation
  },

  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  locale: defaultLocale as string,

  registerI18n (title: string, translations: Translations): void {
    forEach(translations, (_value, key) => {
      invariant(I18n.translationObj.hasOwnProperty(key), `Translations: Language (${key}) has not been implemented in assets/javascripts/utils/translations`)

      // @ts-expect-error No index signature with a parameter of type 'string' was found on type 'Translations'.ts(7053)
      if (I18n.translationObj[key][title]) {
        // eslint-disable-next-line no-console
        console.warn(`Ignore this warning if you're using hot reloading:\n\tTranslations: ${title}.${key} already exists. \`registerI18n\` called multiple times with the same key.`)
      }

      // @ts-expect-error No index signature with a parameter of type 'string' was found on type 'Translations'.ts(7053)
      I18n.translationObj[key][title] = translations[key]
    })
  },

  setLocale (): string {
    if (!navigator) return defaultLocale

    if (navigator.languages?.length) {
      let lang = defaultLocale
      forEach(navigator.languages, (language) => {
        if (includes(I18n.availableLocales, language)) {
          lang = language
          return false
        }
      })
      return lang
    }

    // @ts-expect-error (auto-migrated from flow FixMe)[prop-missing] Property userLanguage is missing in Navigator
    if (navigator.userLanguage && isString(navigator.userLanguage) && includes(I18n.availableLocales, navigator.userLanguage)) {
      // @ts-expect-error (auto-migrated from flow FixMe)[prop-missing] Property userLanguage is missing in Navigator
      return navigator.userLanguage
    }

    if (navigator.language && includes(I18n.availableLocales, navigator.language)) {
      return navigator.language.toString()
    }
    return defaultLocale
  },

  t<T extends React$Element | string> (keyString: string, options?: Options<T> | null): TranslationResult<T> {
    const translation: string = get(I18n.translationObj, [I18n.locale, keyString].join('.'))
      || get(I18n.translationObj, [defaultLocale, keyString].join('.'))

    invariant(translation, `translation not found for key: ${keyString}`)
    return I18n.interpolate(translation, options, keyString)
  },

  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  translationObj: {
    en: {},
    es: {},
  } as Translations,

}

I18n.locale = I18n.setLocale()

function trackInterpolationError<T> (
  {
    keyString,
    options,
    translation,
  }: {
    keyString: string | null | undefined,
    options: Options<T>,
    translation: string
  },
): void {
  raygun('send', {
    customData: {
      keyString,
      options,
      translation,
    },
    error: 'Missing translation interpolation',
    tags: ['I18n'],
  })
}

export default I18n
