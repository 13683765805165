/*
A simple pre-styled divider line, aka <HR>
*/
import { memo } from 'react'
import useStyles from './styles'
import type { Props } from './types'

const Divider = memo<Props>(({
  className = '',
  doubleMargin,
  halfMargin,
  halfMarginBottom,
  halfMarginTop,
  halfSideMargin,
  noMarginBottom,
  noMarginTop,
  sideMargin,
}) => {
  const { classes, cx } = useStyles()

  return (
    <hr
      className={cx(
        classes.divider,
        {
          [classes.margin]: !halfMargin && (!halfSideMargin || !sideMargin),
          [classes.doubleMargin]: doubleMargin,
          [classes.halfMargin]: halfMargin,
          [classes.halfMarginBottom]: !halfMargin && !doubleMargin && halfMarginBottom,
          [classes.halfMarginTop]: !halfMargin && !doubleMargin && halfMarginTop,
          [classes.halfSideMargin]: !sideMargin && halfSideMargin,
          [classes.noMarginBottom]: !halfMargin && !doubleMargin && noMarginBottom,
          [classes.noMarginTop]: !halfMargin && !doubleMargin && noMarginTop,
          [classes.sideMargin]: !halfSideMargin && sideMargin,
        },
        className,
      )}
    />
  )
})

Divider.displayName = 'Divider'

export default Divider
