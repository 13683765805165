import { IMAGE_WIDTH } from 'd2/components/MetadataImage/constants'
import {
  MAX,
  SPACING,
  SPACING_HALF,
} from 'd2/constants'
import { MergedThemes } from 'd2/types'
import { px } from 'd2/utils/style'
import { screenSmMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'

type StylesProps = {
  labelMaxWidth: number | undefined,
}

const styles = (_theme: MergedThemes, { labelMaxWidth }: StylesProps) => ({
  avatarIcon: {
    marginRight: px(SPACING_HALF),
  },
  circleAvatarIcon: {
    '& div > img': {
      border: 'none',
      height: px(50),
      width: px(50),
    },
  },
  color: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    overflowWrap: 'break-word',
    padding: px(SPACING),
    position: 'relative',
  },
  containerWithNoPadding: {
    padding: 0,
  },
  containerWithNoPaddingLeft: {
    padding: `${px(SPACING)} ${px(SPACING)} ${px(SPACING)} 0`,
  },
  containerWithSmallPadding: {
    padding: px(SPACING_HALF),
  },
  fullWidth: {
    width: MAX,
  },
  itemAlignStart: {
    alignItems: 'start',
  },
  labelMaxWidth: {
    ...labelMaxWidth
      ? {
        maxWidth: px(labelMaxWidth),
      }
      : { maxWidth: `calc(${MAX} - ${px(SPACING)})` },
  },
  noImageMarginRight: {
    marginRight: '0',
  },
  noTextMargin: {
    margin: '0',
  },
  text: {
    flex: 1,
    textTransform: 'initial',
  },
  textCenter: {
    textAlign: 'center',
  },
  textInsidePickerButton: {
    margin: `0 ${px(SPACING_HALF)}`,
  },
  textMargin: {
    margin: `0 ${px(SPACING)}`,
    [`@media (max-width: ${px(screenSmMax)})`]: {
      margin: `0 ${px(SPACING_HALF)}`,
    },
  },
  textWidth: {
    width: `calc(100% - ${px(IMAGE_WIDTH + SPACING * 2)})`,
  },
  textWidthWidescreen: {
    width: 0,
  },
  vertical: {
    flexDirection: 'column',
  },
  verticalStretch: {
    height: MAX,
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'MetadataSummary',
})(styles)
