import { Component as ReactComponent } from 'react'
import { forEach } from 'lodash-es'

const UNSAFE_LIFECYCLE_METHOD_NAMES: string[] = ['componentWillMount', 'componentWillReceiveProps', 'componentWillUpdate']

/*
 * This function is not a proper HOC to be precise because it mutates the
 * passed Component argument instead of returning a new one. I only ended up
 * with this approach since it's easier than struggling to make a clone of
 * React class component.
 */
function renameUnsafeLifecycleMethods (Component: typeof ReactComponent): typeof ReactComponent {
  const { prototype } = Component

  forEach(UNSAFE_LIFECYCLE_METHOD_NAMES, (methodName) => {
    // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Component<any, any, any>'. No index signature with a parameter of type 'string' was found on type 'Component<any, any, any>'.ts(7053)
    if (!prototype[methodName]) return

    Object.defineProperty(prototype, `UNSAFE_${methodName}`, {
      configurable: true,
      // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Component<any, any, any>'. No index signature with a parameter of type 'string' was found on type 'Component<any, any, any>'.ts(7053)
      value: prototype[methodName],
      writable: true,
    })
    Reflect.deleteProperty(prototype, methodName)
  })

  return Component
}

export default renameUnsafeLifecycleMethods
