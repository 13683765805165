import { forwardProps } from 'd2/utils/props'
import { forwardRef, memo } from 'react'
import AvatarIcon from '../AvatarIcon'
import useTheme from 'd2/hooks/useTheme'
import type { Props } from './types'

const GradientIcon = memo<Props>(forwardRef(({
  ...props
}, ref) => {
  const theme = useTheme()

  return (
    <AvatarIcon
      background={theme.branding?.primaryGradient}
      // @ts-expect-error Type 'ForwardedRef<unknown>' is not assignable to type 'LegacyRef<HTMLSpanElement> | undefined'.
      ref={ref}
      {...forwardProps(props)}
    />
  )
}))

GradientIcon.displayName = 'GradientIcon'

export default GradientIcon
