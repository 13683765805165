import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

// TODO: Move sizes to theme
const styles = (theme: MergedThemes) => ({
  heading3: {
    fontFamily: theme.branding?.secondaryFontFamily,
    fontSize: px(28),
    lineHeight: px(35),
  },
} as const)

export default makeStyles({
  name: 'Heading3',
})(styles)
